body {
    background-color: #0C161C;
    color: #F9FAFA;
    min-height: 100vh;
}

.App {
    padding: 30px;
}

.App > img {
    width: 100px;
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.examples {
    font-size: 20px;
}

.model-info-input {
    height: 50px;
    width: 380px;
    padding: 0px 15px;
    font-size: 20px;
    background-color: #ccc;
    border: 3px solid white;
    border-radius: 6px;
    margin: 10px 0px;
}

.model-info-table td{
    padding: 5px 10px;
    background-color: #F7FF1A;
    font-size: 25px;
    color: #0C161C
}

.model-info-table td:first-child{
    font-weight: bold;
}

.model-info-table a {
    color:#0C161C
}

.warning {
    color: rgb(234, 39, 202)
}
